export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/account-new',
    name: 'account-new',
    component: () => import('@/views/auth/CreateAccount.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/renew-password-demand',
    name: 'renew-password-demand',
    component: () => import('@/views/auth/RenewLoginDemand.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/mes-credits',
    name: 'mes-credits',
    component: () => import('@/views/clients/myCredits.vue'),
    meta: {
      layout: 'vertical',
      banner: false,
    },
  },
  {
    path: '/mes-evaluations',
    name: 'mes-evaluations',
    component: () => import('@/views/clients/myEvaluations.vue'),
    meta: {
      layout: 'vertical',
      banner: true,
    },
  },
  {
    path: '/nouvelle-evaluation',
    name: 'nouvelle-evaluation',
    component: () => import('@/views/clients/evaluation/newEvaluation.vue'),
    meta: {
      layout: 'vertical',
      banner: true,
    },
  },
  {
    path: '/renew-password',
    name: 'renew-password',
    component: () => import('@/views/auth/RenewLogin.vue'),
    meta: {
      layout: 'full',
    },
  },
]
