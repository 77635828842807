/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    currentUser: {},
    userPacks: [
      {
        id: 1,
        qty: 1,
        discount: 0,
        unitPrice: null,
        packPrice: null,
      },
      {
        id: 5,
        qty: 5,
        discount: 0,
        unitPrice: null,
        packPrice: null,
      },
      {
        id: 10,
        qty: 10,
        discount: 0,
        unitPrice: null,
        packPrice: null,
      },
      {
        id: 50,
        qty: 50,
        discount: 0,
        unitPrice: null,
        packPrice: null,
      },
    ],
  },
  getters: {
    modalData: state => state.modalShow,
    currentUser: state => state.currentUser,
    getUserPacks(state) {
      // eslint-disable-next-line prefer-const
      let packs = state.userPacks
      packs.forEach(pack => {
        pack.unitPrice = state.currentUser.tarif_credit
        pack.packPrice = (pack.unitPrice * pack.qty) * (1 - (pack.discount / 100))
      })
      return packs
    },
  },
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    createAccount({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/user', data).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error.response.data)
          },
        )
      })
    },
    // eslint-disable-next-line no-unused-vars
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/login', data).then(
          response => {
            commit('setCurrentUser', response.data.user)
            resolve(response.data)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendNewPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put('/auth/password', data).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    // eslint-disable-next-line no-unused-vars
    checkRenewPasswordToken({ commit }, token) {
      const body = { token }
      return new Promise((resolve, reject) => {
        axios.post('/auth/password/renew/checktoken', body).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    // eslint-disable-next-line no-unused-vars
    checkToken({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get('/auth/checktoken/', { params: data }).then(
          response => {
            commit('setCurrentUser', JSON.stringify(response.data))
            resolve(response.data)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendRenewDemand({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/password/renew/demand', data).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendContactForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/auth/contactFormSqe', data).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error.response.data)
          },
        )
      })
    },
  },
}
