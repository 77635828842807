/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    allCredits: [],
  },
  getters: {
    allCredits(state) {
      return state.allCredits
    },

  },
  mutations: {
    setAllCredits(state, value) {
      state.allCredits = value
    },
  },
  actions: {
    postCreditBying({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios.post('credits/buying', body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    getAllCreditsFacturesFromClient({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('credits/allByClient', { params })
          .then(response => {
            commit('setAllCredits', response.data.credits)
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    getAllCreditsFactures({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('credits/all', { params })
          .then(response => {
            commit('setAllCredits', response.data.credits)
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    getFacturePDF({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get(`credits/${params.id}/facture`, { responseType: 'arraybuffer' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
  },
}
