import Vue from 'vue'
import {
  ToastPlugin, ModalPlugin,
  BCarousel, CarouselPlugin,
  BSpinner,
} from 'bootstrap-vue'

import VueCompositionAPI from '@vue/composition-api'
import { extend } from 'vee-validate'
// import VeeValidate from 'vee-validate';

import VueLuxon from 'vue-luxon'

import i18n from '@/libs/i18n'
import { marked } from 'marked'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

Vue.prototype.$Marked = marked
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
// Vue.use(VeeValidate)

Vue.use(VueLuxon)

Vue.component('UpdateCreditModale', () => import('./views/modals/UpdateCreditModale.vue'))
Vue.component('SuccessUpdateModale', () => import('./views/modals/SuccessUpdateModale.vue'))
Vue.component('SuccessUpdateTarifCreditModale', () => import('./views/modals/SuccessUpdateTarifCreditModale.vue'))
Vue.component('UpdateTarifCredit', () => import('./views/modals/UpdateTarifCredit.vue'))
Vue.component('FashInfosBanner', () => import('./views/components/FlashInfosBanner.vue'))
Vue.component('b-carousel', BCarousel)
Vue.use(CarouselPlugin)
// Composition API
Vue.use(VueCompositionAPI)

extend('maxLengthIntitule', {
  message:
    ' Le champ {_field_} doit contenir entre 3 et 255 caractères',
  validate: value => /^.{3,255}$/.test(value) // Test the regex. Test function returns a true or false value.
  ,
})

extend('maxLengthAbreviation', {
  message:
    'Le champ {_field_} doit contenir entre 5 et 255 caractères',
  validate: value => /^.{5,255}$/.test(value) // Test the regex. Test function returns a true or false value.
  ,
})

Vue.component('b-spinner', BSpinner)
// Feather font icon - For form-wizard
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@core/scss/vue/libs/vue-select.scss')
require('@core/scss/vue/libs/vue-good-table.scss')

const config = require('../config')

Vue.config.productionTip = !config.devTools
Vue.config.devTools = config.devTools

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
