/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    evaluations: [],
    currentEvaluation: {},
  },
  getters: {
    evaluations(state) {
      return state.evaluations
    },
    currentEvaluation(state) {
      return state.currentEvaluation
    },

  },
  mutations: {
    evaluations(state, value) {
      state.evaluations = value
    },
    currentEvaluation(state, value) {
      state.currentEvaluation = value
    },
  },
  actions: {
    updateEvaluation({ commit }, { id, etat }) {
      return new Promise((resolve, reject) => {
        axios.put('evaluations/', { id, etat })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    getEvalCorrection({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get(`evaluations/${params.id}/correction`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    postEvalReponse({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios.post('evaluations/reponses', body)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    createEval({ commit }, body) {
      return new Promise((resolve, reject) => {
        axios.post('evaluations', body)
          .then(response => {
            commit('currentEvaluation', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    getEval({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get(`evaluations/${params.id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    getAllEvals({ commit }, filter) {
      return new Promise((resolve, reject) => {
        axios.post('evaluations/getAllFiltered', filter)
          .then(response => {
            commit('currentEvaluation', response.data)
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    generatePDF({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get(`evaluations/${params.id}/pdf`, { responseType: 'arraybuffer' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    demandeDetail({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post('evaluations/demandeDetail', params)
          .then(reponse => {
            resolve(reponse)
          })
          .catch(error => reject(error || error.message))
      })
    },
  },
}
