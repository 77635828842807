/* eslint-disable import/no-cycle */
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import auth from './modules/auth'
import fonctionCompetence from './modules/fonction_competence'
import customFilter from './modules/custom_filter'
import user from './modules/user'
import flashinfo from './modules/flashinfo'
import credits from './modules/credits'
import questionReponse from './modules/questionReponse'
import evaluation from './modules/evaluation'

import timer from './components/timer'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    fonctionCompetence,
    flashinfo,
    customFilter,
    user,
    credits,
    timer,
    questionReponse,
    evaluation,
  },
  strict: process.env.DEV,
})
