// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    questionTemplate: {
      categorieId: 1,
      fonctions: [],
      competences: [],
      libelle: '',
      media_question: '',
      actif: 1,
    },
    currentQuestion: {},
    questions: [],
    categories: [],
  },
  getters: {
    currentQuestion(state) {
      return state.currentQuestion
    },
    questions(state) {
      return state.questions
    },
    questionTemplate(state) {
      return state.questionTemplate
    },
    categories(state) {
      return state.categories
    },
  },
  mutations: {
    questions(state, value) {
      state.questions = value
    },
    currentQuestion(state, value) {
      state.currentQuestion = value
    },
    categories(state, value) {
      state.categories = value
    },
  },
  actions: {
    getAllCategories({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        axios.get('/categories').then(response => {
          commit('categories', response.data)
          resolve(response.data)
        }).catch(error => reject(error || error.message))
      })
    },
    getAllQuestions({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/questions', { params }).then(response => {
          commit('questions', response.data)
          resolve(response.data)
        })
          .catch(error => reject(error || error.message))
      })
    },
    getQuestion({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/questions/${id}`).then(response => {
          resolve(response.data)
        })
          .catch(error => reject(error || error.message))
      })
    },
    updateQuestion({ commit }, data) {
      return new Promise((resolve, reject) => {
        console.log(data)
        axios.put('/questions', data).then(response => {
          resolve(response.data)
        })
          .catch(error => reject(error || error.message))
      })
    },
    postQuestion({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/questions', data).then(response => {
          resolve(response.data)
        })
          .catch(error => reject(error || error.message))
      })
    },
    postBulkReponses({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/reponses/bulk', data).then(response => {
          resolve(response.data)
        })
          .catch(error => reject(error || error.message))
      })
    },
    postBulkQuestionsReponses({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/reponseQuestions/bulk', data).then(response => {
          resolve(response.data)
        })
          .catch(error => reject(error || error.message))
      })
    },
  },
}
