export default [
  {
    path: '/admin/utilisateurs',
    name: 'admin-utilisateurs',
    component: () => import('@/views/admin/users.vue'),
    meta: {
      layout: 'vertical',
      pageTitle: 'Gestion Utilisateurs',
      requiresAuth: true,
      banner: false,
    },
  },
  {
    path: '/admin/flash-infos',
    name: 'admin-flashinfo',
    component: () => import('@/views/admin/flashinfos.vue'),
    meta: {
      layout: 'vertical',
      pageTitle: 'Gestion Flashs Infos',
      requiresAuth: true,
      banner: false,
    },
  },
  {
    path: '/admin/flash-infos/edit',
    name: 'admin-flashinfo-edit',
    component: () => import('@/views/admin/EditionInfoFlash.vue'),
    meta: {
      layout: 'vertical',
      pageTitle: 'Edition Info Flash',
      requiresAuth: true,
      banner: false,
    },
  },
  {
    path: '/admin/fonctions',
    name: 'admin-fonctions',
    component: () => import('@/views/admin/competences-fonctions/fonctions.vue'),
    meta: {
      layout: 'vertical',
      pageTitle: 'Gestion Fonctions',
      requiresAuth: true,
      banner: false,
    },
  },
  {
    path: '/admin/competences',
    name: 'admin-competences',
    component: () => import('@/views/admin/competences-fonctions/competences.vue'),
    meta: {
      layout: 'vertical',
      pageTitle: 'Gestion Compétences',
      requiresAuth: true,
      banner: false,
    },
  },
  {
    path: '/admin/questions-reponses',
    name: 'admin-questions-reponses',
    component: () => import('@/views/admin/questionsReponses.vue'),
    meta: {
      layout: 'vertical',
      pageTitle: 'Gestion Questions - Réponses',
      requiresAuth: true,
      banner: false,
    },
  },
  {
    path: '/admin/questions-reponses/new/:id?',
    name: 'admin-new-questions-reponses',
    component: () => import('@/views/admin/evaluation/NewQuestion.vue'),
    meta: {
      layout: 'vertical',
      pageTitle: 'Gestion Questions - Réponses',
      requiresAuth: true,
      banner: false,
    },
  },
]
