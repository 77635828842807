export default {
  namespaced: true,
  state: {
    filter: {
      actif: '',
      libelle: '',
    },
  },
  getters: {
    getFilter(state) {
      return state.filter
    },
  },
  mutations: {
    setFilter(state, value) {
      state.filter = value
    },
  },
  actions: {
    setFilter({ commit }, data) {
      commit('setFilter', data)
    },
  },
}
