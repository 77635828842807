export default [
  {
    header: 'Mon Espace',
  },
  {
    title: 'Nouvelle évaluation',
    route: 'nouvelle-evaluation',
    icon: 'UsersIcon',
  },
  {
    title: 'Mes évaluations',
    route: 'mes-evaluations',
    icon: 'ZapIcon',
  },
  {
    title: 'Mes crédits',
    route: 'mes-credits',
    icon: 'BoxIcon',
  },
]
