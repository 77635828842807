export default [
  {
    header: 'Gestion',
  },
  {
    title: 'Utilisateurs',
    route: 'admin-utilisateurs',
    icon: 'UsersIcon',
  },
  {
    title: 'Infos Flash',
    route: 'admin-flashinfo',
    icon: 'ZapIcon',
  },
  {
    title: 'Fonctions',
    route: 'admin-fonctions',
    icon: 'BoxIcon',
  },
  {
    title: 'Compétences',
    route: 'admin-competences',
    icon: 'CodepenIcon',
  },
  {
    title: 'Questions/Réponses',
    route: 'admin-questions-reponses',
    icon: 'SettingsIcon',
  },
]
