import { $themeConfig } from '@themeConfig'
import adminItems from '@/navigation/vertical/admin'
import clientItems from '@/navigation/vertical/client'

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
  },
  getters: {
    navItems(state, getters, rootState, rootGetters) {
      const currentUser = rootGetters['auth/currentUser']
      let naviguation = clientItems
      if (currentUser?.isAdmin) {
        naviguation = [
          ...naviguation,
          ...adminItems,
        ]
      }
      return [
        ...naviguation,
      ]
    },
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
  },
  actions: {},
}
