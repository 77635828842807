<template>
  <div />
</template>
<script>

export default {
  computed: {
    params() {
      return this.$store.getters['app/getModalParams']
    },
  },
  watch: {
    params() {
      this.showModal()
    },
  },
  methods: {
    showModal() {
      const sentence1 = this.params.sentence1 ? this.params.sentence1 : ''
      const sentence2 = this.params.sentence2 ? this.params.sentence2 : ''
      const textContent = `<p>${sentence1}</p><p>${sentence2}</p>`
      this.$swal({
        html: textContent,
        title: this.params.title ? this.params.title : 'Information',
        icon: this.params.type ? this.params.type : 'info',
        allowOutsideClick: this.params.outsideClickClosable,
        allowEscapeKey: this.params.escapeKeyClosable,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}

</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss"

</style>
