export default {
  namespaced: true,
  state: {
    countDown: 0,
    isNotStart: true,
    isTempsEcoule: false,
    timeStart: null,
  },
  getters: {
    getCountDown(state) {
      return state.countDown
    },
    getIsNotStart(state) {
      return state.isNotStart
    },
    getIsTempsEcoule(state) {
      return state.isTempsEcoule
    },
    getTimeStart(state) {
      return state.timeStart
    },
  },
  mutations: {
    setCountDown(state, value) {
      state.countDown = value
    },
    setTimeStart(state, value) {
      state.timeStart = value
    },
    setHidden(state, value) {
      state.isNotStart = value
    },
    setIsTempsEcoule(state, value) {
      state.isTempsEcoule = value
    },
  },
  actions: {
    onStart({ commit }, data) {
      commit('setHidden', data || false)
    },
    getCountDown({ commit }, data) {
      return new Promise((resolve, reject) => {
        const response = 1800
        resolve(response)
        commit('setCountDown', response)
      })
    },
    getTimeStart({ commit }, data) {
      return new Promise((resolve, reject) => {
        const response = Date.now() + 2000 // On ajoute 2 secondes au depart pour avoir le chrono à fond à l'affichage
        resolve(response)
        commit('setTimeStart', response)
      })
    },
    setTempsEcoule({ commit }, value) {
      commit('setIsTempsEcoule', value)
    },
  },
}
