import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    modalShow: {
      show: false,
      title: '',
      text: '',
      btn: '',
    },
    isLoading: false,
    modalParams: {
      title: '',
      sentence1: '',
      sentence2: '',
      type: 'info', // 'info', 'success', 'error', 'warning', 'question'
      outsideClickClosable: false,
      escapeKeyClosable: false,
    },
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getIsLoading: state => state.isLoading,
    getModalParams: state => state.modalParams,
    modalData: state => state.modalShow,
  },
  mutations: {
    setLoader(state, val) {
      state.isLoading = val
    },
    setModalParams(state, val) {
      state.modalParams = val
    },
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    setLoader({ commit }, value) {
      commit('setLoader', value)
    },
    callModal({ commit }, value) {
      commit('setModalParams', value)
    },
  },
}
