// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    filter: {
      actif: '',
      libelle: '',
    },
    total: 0,
    users: [],
    societes: [],
    agences: [],
    notFound: '',
    updatecreditFaillure: '',
    updateUserToAdminSuccess: '',
    updateUserToAdminFaillure: '',
    updateTarifcreditSuccess: {
      message: '',
      status: false,
    },
    updatecreditFaillureTarifCredit: {
      message: '',
      status: false,
    },
    updatecreditSuccess: {
      message: '',
      status: false,
    },
  },
  getters: {
    getAllUsers(state) {
      return state.users
    },
    getTotal(state) {
      return state.total
    },
    getSocietes(state) {
      return state.societes
    },
    getAgences(state) {
      return state.agences
    },
    getNotFoundInfo(state) {
      return state.notFound
    },
    getMessageUpdatecredit(state) {
      return state.updatecreditSuccess
    },
    getMessageUpdateTarifCredit(state) {
      return state.updateTarifcreditSuccess
    },
  },
  actions: {
    getAllUsers({ commit }, params) {
      axios.get('users', { params })
        .then(res => {
          commit('setUsers', res.data.users)
          commit('setTotal', res.data.total)
        })
        .catch(err => {
          console.log('err', err)
          commit('setEmptyFilter', 'Aucun résultat associé à cette recherche.')
          commit('setUsers', [])
          commit('setTotal', 0)
        })
    },

    getAllSocietes({ commit }, { id_user }) {
      axios.get('users/societes', { params: { id: id_user } })
        .then(res => {
          commit('setAllSociete', res.data)
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    getAllagences({ commit }) {
      axios.get('users/agences')
        .then(res => {
          commit('setAllagence', res.data)
        })
        .catch(err => {
          console.log('err', err)
        })
    },
    updateCredit({ commit, dispatch }, data) {
      axios.put('users', data)
        .then(res => {
          if (res.status === 200) {
            dispatch('getAllUsers')
            commit('updatecredisuccess', 'La modification du crédit a été realisée avec succés.')
          }
        })
        .catch(err => {
          commit('updatecreditFaillure', err)
        })
    },
    getCredit({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`users/getCredits/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => reject(error || error.message))
      })
    },
    updateTarifCredit({ commit, dispatch }, data) {
      const info = { amount: data.newTarifCredit }
      axios.post(`users/${data.id}/credits/add`, info)
        .then(res => {
          console.log('res', res)
          if (res.status === 200) {
            dispatch('getAllUsers')
            commit('updateTarifcreditSuccess', 'La modification du tarif crédit a été realisée avec succés.')
          }
        })
        .catch(err => {
          commit('updatecreditFaillureTarifCredit', err)
        })
    },

    updateUserToAdmin({ commit, dispatch }, params) {
      console.log(params)
      axios.put(`users/${params.id}/${params.action}`, { id: params.idUser, role: params.role })
        .then(res => {
          commit('updateUserToAdminSuccess', res.data)
          dispatch('getAllUsers')
        })
        .catch(err => {
          commit('updateUserToAdminFaillure', err)
        })
    },
  },
  mutations: {
    setUsers(state, value) {
      state.users = value
    },
    setTotal(state, value) {
      state.total = value
    },
    setAllSociete(state, data) {
      state.societes = data
    },
    setAllagence(state, data) {
      state.agences = data
    },
    setEmptyFilter(state, data) {
      state.notFound = data
    },
    updatecredisuccess(state, data) {
      state.updatecreditSuccess.message = data
      state.updatecreditSuccess.status = true
    },
    updatecreditFaillure(state, data) {
      state.updatecreditFaillure = data
    },
    updateUserToAdminSuccess(state, data) {
      state.updateUserToAdminSuccess = data
    },
    updateUserToAdminFaillure(state, data) {
      state.updateUserToAdminFaillure = data
    },
    updateTarifcreditSuccess(state, data) {
      state.updateTarifcreditSuccess.message = data
      state.updateTarifcreditSuccess.status = true
    },
    updatecreditFaillureTarifCredit(state, data) {
      state.updatecreditFaillureTarifCredit.message = data
      state.updatecreditFaillureTarifCredit.status = true
    },
  },

}
