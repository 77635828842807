import Vue from 'vue'
import VueRouter from 'vue-router'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import pages from './routes/pages'
import admin from './routes/admin'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_FRONTBASE,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/clients/myCredits.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Home',
      },
    },
    ...pages,
    ...admin,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  // if (to.name === 'auth-login' && localStorage.getItem('userData')) {
  //   next({ name: 'home' })
  // }
  if (to.meta.banner) {
    store.commit('flashinfo/setBannerActive', true)
  } else {
    store.commit('flashinfo/setBannerActive', false)
  }
  if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    next({ name: 'auth-login' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
