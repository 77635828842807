import Vue from 'vue'

// axios
import axios from 'axios'
// eslint-disable-next-line
import router from '../router'

const config = require('../../config')

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: config.api.baseUrl,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

// Add a response interceptor
// axiosIns.interceptors.response.use(response => response,
//   error => {
//     if (error.response.status === 401) {
//       router.push('/login')
//     }
//     return Promise.reject(error)
//   })

Vue.prototype.$http = axiosIns

export default axiosIns
