// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    flashinfos: [],
    bannerActive: true,
  },
  getters: {
    flashinfos(state) {
      return state.flashinfos
    },
    flashinfosActive(state) {
      return state.flashinfos.filter(flash => flash.actif)
    },
    bannerActive(state) {
      return state.bannerActive
    },
  },
  mutations: {
    setFlashInfos(state, value) {
      state.flashinfos = value
    },
    setBannerActive(state, value) {
      state.bannerActive = value
    },
  },
  actions: {
    getAllFlashinfos({ commit }, paramsPassed) {
      const finalParams = {}
      if (paramsPassed) {
        if (paramsPassed.actif === 'Actif') {
          finalParams.actif = 1
        } else if (paramsPassed.actif === 'Inactif') {
          finalParams.actif = 0
        } else {
          finalParams.actif = null
        }
      }
      return new Promise((resolve, reject) => {
        axios.get('/infoflashs', { params: finalParams }).then(
          response => {
            commit('setFlashInfos', response.data)
            resolve(response.data)
          },
        )
          .catch(error => reject(error || error.message))
      })
    },
    updateFlashInfo({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        axios.put('infoflashs', paramsPassed, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error || error.message))
      })
    },
    createFlashInfo({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        axios.post('infoflashs', paramsPassed, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => resolve(response))
          .catch(error => reject(error || error.message))
      })
    },
  },
}
