// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    competencesRows: null,
  },
  getters: {
    getCompetencesRows(state) {
      return state.competencesRows
    },
  },
  mutations: {
    setCompetencesRows(state, value) {
      state.competencesRows = value
    },
  },
  actions: {
    getAllCompetences({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        const finalParams = {}
        if (paramsPassed) {
          finalParams.libelle_competence = paramsPassed.libelle
          if (paramsPassed.actif === 'Actif') {
            finalParams.actif = 1
          } else if (paramsPassed.actif === 'Inactif') {
            finalParams.actif = 0
          } else {
            finalParams.actif = null
          }
        }
        axios.get('/competences', { params: finalParams }).then(response => resolve(response.data))
          .catch(error => reject(error || error.message))
      })
    },
    updateCompetence({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        axios.put('competences', paramsPassed)
          .then(response => resolve(response))
          .catch(error => reject(error || error.message))
      })
    },
    createCompetence({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        axios.post('competences', paramsPassed)
          .then(response => resolve(response))
          .catch(error => reject(error || error.message))
      })
    },
    getAllFonctions({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        const finalParams = {}
        if (paramsPassed) {
          finalParams.libelle_fonction = paramsPassed.libelle
          if (paramsPassed.actif === 'Actif') {
            finalParams.actif = 1
          } else if (paramsPassed.actif === 'Inactif') {
            finalParams.actif = 0
          } else {
            finalParams.actif = null
          }
        }
        axios.get('/fonctions', { params: finalParams }).then(response => resolve(response.data))
          .catch(error => reject(error || error.message))
      })
    },
    updateFonction({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        axios.put('fonctions', paramsPassed)
          .then(response => resolve(response))
          .catch(error => reject(error || error.message))
      })
    },
    createFonction({ commit }, paramsPassed) {
      return new Promise((resolve, reject) => {
        axios.post('fonctions', paramsPassed)
          .then(response => resolve(response))
          .catch(error => reject(error || error.message))
      })
    },
  },
}
